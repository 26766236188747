import React from 'react';
import Layout from '../components/layout';

const about = () => {
    return (
        <Layout>
            About
        </Layout>

    );
};

export default about;