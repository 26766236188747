import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React, { useEffect, useRef, useState } from 'react';
import menuIcon from "@mojang/web-theme-bootstrap/assets/svg/icons/color-menu.svg"
import closeIcon from "@mojang/web-theme-bootstrap/assets/svg/icons/color-close.svg"

const Navbar = () => {
    const [showNav, setShowNav] = useState(false);
    const [winWidth, setWinWidth] = useState(0);

    const handleResize = (e) => {
        setWinWidth(window.innerWidth)
    };
    console.log(winWidth);

    const mounted = useRef();
    useEffect(() => {
        if (!mounted.current) {
            // do componentDidMount logic
            window.addEventListener("resize", handleResize());
            mounted.current = true;
        } else {
            // do componentDidUpdate logic
            window.addEventListener("resize", handleResize());
        }
    });
    

    // console.log(window.screen.availWidth);
    return (

        <nav class="navbar navbar-expand-lg bg-dark shadow navbar-dark fixed-top static-top">
            <div class="container">
                <Link
                    className="navbar-brand"
                    to='/'
                >
                    <StaticImage
                        src="../assets/images/minecraft-india.png"
                        alt="Minecraft India"

                        placeholder="blurred"
                        layout="fixed"
                        width={200}
                    />
                </Link>
                <img class="navbar-toggler btn-dark" src={showNav ? closeIcon : menuIcon} style={{ width: '50px' }} onClick={() => setShowNav(!showNav)} alt="menu" />
                <div class="collapse navbar-collapse" id="navbarResponsive">
                    {winWidth < 992 && showNav && (
                        <ul className='navbar-nav ml-auto my-2'>
                            <li class="nav-item active">
                                <Link
                                    className="nav-link space"
                                    activeClassName="active"
                                    to='/'
                                >Home</Link>

                            </li>

                            <li class="nav-item my-2">
                                <Link
                                    className="nav-link curr-act space hover-me"
                                    activeClassName="active"
                                    to='/about'
                                >About</Link>

                            </li>

                            <li class="nav-item my-2">
                                <a class="nav-link " target="_blank" rel="norefferer" href="http://blog.minecraftindia.in">Blog</a>

                            </li>

                            <li class="nav-item dropdown my-2 space">
                                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                    Join Server/Realm
          </a>
                                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <a class="dropdown-item" href="http://localhost/MinecraftIndia/index.php/Theme/r">Join Server</a>
                                    <a class="dropdown-item" href="http://localhost/MinecraftIndia/index.php/Theme/nr">Join Realm</a>
                                </div>
                            </li>

                            <li class="nav-item my-2 dropdown space">
                                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Support
          </a>
                                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <a class="dropdown-item" href="http://localhost/MinecraftIndia/index.php/Theme/r">Report Someting/Somone</a>
                                    <a class="dropdown-item" href="http://localhost/MinecraftIndia/index.php/Theme/nr">Contact Us</a>
                                </div>
                            </li>
                        </ul>

                    )}
                    {winWidth > 992 && (
                        <ul className="navbar-nav ml-auto">
                            <li class="nav-item my-2">
                                <Link
                                    className="nav-link space"
                                    activeClassName="active"
                                    to='/'
                                >Home</Link>

                            </li>

                            <li class="nav-item my-2">
                                <Link
                                    className="nav-link curr-act space hover-me"
                                    activeClassName="active"
                                    to='/about'
                                >About</Link>

                            </li>

                            <li class="nav-item my-2">
                                <a class="nav-link " target="_blank" rel="norefferer" href="http://blog.minecraftindia.in">Blog</a>

                            </li>

                            <li class="nav-item dropdown my-2 space">
                                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                    Join Server/Realm
</a>
                                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <a class="dropdown-item" href="http://localhost/MinecraftIndia/index.php/Theme/r">Join Server</a>
                                    <a class="dropdown-item" href="http://localhost/MinecraftIndia/index.php/Theme/nr">Join Realm</a>
                                </div>
                            </li>

                            <li class="nav-item my-2 dropdown space">
                                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Support
</a>
                                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <a class="dropdown-item" href="http://localhost/MinecraftIndia/index.php/Theme/r">Report Someting/Somone</a>
                                    <a class="dropdown-item" href="http://localhost/MinecraftIndia/index.php/Theme/nr">Contact Us</a>
                                </div>
                            </li>
                        </ul>
                    )}

                </div>
            </div>
        </nav >
    );
};

export default Navbar;